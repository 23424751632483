import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../Redux/actions/authActions'
import logo from '../assets/pantrylogo.png'



class Footer extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <footer className="footer">
                <div className="container footer-link">
                    <ul className="pt-3">
                        <li> <Link to="/about"> About us  </Link></li><span className="px-1 text-white"> | </span>
                        <li> <Link to="/contact"> Contact </Link> </li> <span className="px-1 text-white"> | </span>
                        <li> <Link to="/contact#video"> How to video's </Link></li> <span className="px-1 text-white"> | </span>
                        <li> <Link to="/privacy"> Privacy & Cookies </Link> </li> <span className="px-1 text-white"> | </span>
                        <li> <Link to="/faq"> FAQ </Link></li> <span className="px-1 text-white"> | </span>
                        <li> <Link to="http://www.beelicensed.com"> Partners </Link>
                        </li>
                    </ul>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Footer)