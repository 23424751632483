import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import { Form, Icon, Input, Button } from 'antd';
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'

const { TextArea } = Input



class LoginContact extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="advertisement">
                </div>
                <header className="header1 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <a className="navbar-brand" href="#"><img src={logo} alt="logo" className="img-fluid" /></a>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="main-content-bg">

                    <div id="contact"></div>


                    <section className="contact">
                        <div className="center1">
                            <div className="card2">
                                <Form
                                    className="login-form form-w"
                                    hideRequiredMark={true}
                                    encType="multipart/form-data">
                                    <h1 className="heading1" >Contact</h1>
                                    <Form.Item
                                        label="Name"
                                    >
                                        {getFieldDecorator('name', {
                                            rules: [{ required: true, message: 'Please input Name!' }],
                                        })(
                                            <Input
                                                type="text"
                                                placeholder="Name"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                    >
                                        {getFieldDecorator('email', {
                                            rules: [{ required: true, message: 'Please input Email!' }],
                                        })(
                                            <Input
                                                type="email"
                                                placeholder="Email"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item
                                    >
                                        {getFieldDecorator('description', {
                                            rules: [{ required: true, message: 'Please input Username!' }],
                                        })(
                                            <TextArea
                                                rows={3}
                                                placeholder="Please mail your question or remark, we answer all our mail normally within 3 working days."
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="submit-btn">
                                            <Button htmlType="submit" className="btn" style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                                Send
                                        </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </section>
                </div>

                <Footer {...this.props} />
            </div>
        )
    }
}

const LoginContactForm = Form.create({ name: 'normal_login' })(LoginContact);

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(LoginContactForm)