
import React from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../../Redux/actions/authActions'

import image1 from '../../assets/images/slide-img-1.png'
import image2 from '../../assets/images/slide-img-2.png'
import image3 from '../../assets/images/slide-img-3.png'
import { Link } from 'react-router-dom';
import logo from '../../assets/pantrylogo.png'
// import { Slide } from 'react-slideshow-image';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Footer from '../../Components/Footer'
import $ from "jquery";

const slideImages = [
    image1,
    image2,
    image3
];

const properties = {
    // duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    height: 100,
    onChange: (oldIndex, newIndex) => {
        console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    }
}


class Home extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        const { user } = this.props

        if(!user){
            this.props.history.replace('/login')
        }
    }


    render() {
        return ((
            <div>

                <section className="section">

                    <div className="header bg-light">
                        <header className="header bg-light">
                            <nav className="navbar  navbar-light">
                                <div className="container">
                                    <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div className="collapse navbar-collapse" id="collapsingNavbar">
                                        <ul className="navbar-nav ml-auto">

                                            <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    Settings
                                                </a>
                                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">


                                                    <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                                    <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                                    <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                                    <Link className="dropdown-item" to="/ad">Promotional Ads settings</Link>

                                                </div>
                                            </li>
                                            <li className="nav-item"><Link className="nav-link" to="/insight">Insights</Link> </li>
                                            <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                            <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                            <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </header>
                    </div>

                    <div className="slide-container">

                        <Carousel showThumbs={false} showStatus={false} infiniteLoop={true}>
                            <div>
                                <img src={slideImages[0]} />
                            </div>
                            <div>
                                <img src={slideImages[1]} />
                            </div>
                            <div>
                                <img src={slideImages[2]} />
                            </div>
                        </Carousel>
                    </div>
                    <br />
                    <br />
                    <br />


                    <div className="main">
                        <div>
                            <Link to="/insight">INSIGHTS</Link>
                        </div>
                        <div>
                            <Link to="/qanda">Q & A</Link>
                        </div>

                        <div>
                            <Link to="/1to1">1 TO 1 OFFERS</Link>
                        </div>
                    </div>


                    <Footer {...this.props} />

                </section>
            </div>
        ))
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Home)