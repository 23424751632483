import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../../Redux/actions/authActions'
import { Form, Icon, Input, Button, Upload, Select, Table, DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import logo from '../../assets/pantrylogo.png'
import Footer from '../../Components/Footer'

const saleType = ["A", "B", "C"]

const data = [];
for (let i = 1; i < 46; i++) {
    data.push({
        key: i,
        name: `Q&A_00${i}`,
        topic: i,
        ans1: 'h',
        consumer: i,
        address: i > 4 ? `A_0${Math.round(i / 4) > 4 ? Math.round(Math.round(i / 4) / 4) : Math.round(i / 4)}` : `A_0${i}`
    });
}

class Insight extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRowKeys: [],
            loading: false,
            filteredInfo: null,
            sortedInfo: null,
            searchText: '',
            searchedColumn: '',
        }
    }

    componentWillMount() {
        const { user } = this.props

        if (!user) {
            return this.props.history.replace('/login')
        }
    }

    handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    clearFilters = () => {
        this.setState({ filteredInfo: null });
    };

    clearAll = () => {
        this.setState({
            filteredInfo: null,
            sortedInfo: null,
        });
    };

    setAgeSort = () => {
        this.setState({
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    expandedRowRender = () => {
        const columns = [
            {
                title: 'Focus Group',
                ellipsis: true,
                children: [
                    {
                        title: 'Name 1',
                        dataIndex: 'topic2',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 2',
                        dataIndex: 'topic3',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 3',
                        dataIndex: 'topic4',
                        ellipsis: true,
                    }
                ]
            },
            {
                title: 'Products Name',
                ellipsis: true,
                children: [
                    {
                        title: 'Name 1',
                        dataIndex: 'ans1',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 2',
                        dataIndex: 'ans2',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 3',
                        dataIndex: 'ans3',
                        ellipsis: true,
                    }
                ]
            }
        ]
        const data = [];
        for (let i = 0; i < 1; ++i) {
            data.push({
                key: i,
                topic2: '2014-12-24 23:12:00',
                name: 'This is production name',
                upgradeNum: 'Upgraded: 56',
            });
        }
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    expandedRowRender1 = () => {
        const columns = [
            {
                title: 'Focus Group',
                ellipsis: true,
                children: [
                    {
                        title: 'Name 1',
                        dataIndex: 'topic2',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 2',
                        dataIndex: 'topic3',
                        ellipsis: true,
                    },
                    {
                        title: 'Name 3',
                        dataIndex: 'topic4',
                        ellipsis: true,
                    }
                ]
            }
        ]
        const data = [];
        for (let i = 0; i < 1; ++i) {
            data.push({
                key: i,
                topic2: '2014-12-24 23:12:00',
                name: 'This is production name',
                upgradeNum: 'Upgraded: 56',
            });
        }
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };

    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: '1to1 Name',
                dataIndex: 'name',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name')
            },
            {
                title: 'Offer Type',
                dataIndex: 'offer',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'offer' && sortedInfo.order,
                ellipsis: true
            },
            {
                title: 'Start Date',
                dataIndex: 'sDate',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'sDate' && sortedInfo.order
            },
            {
                title: 'End Date',
                dataIndex: 'topic',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'eDate' && sortedInfo.order
            },
            {
                title: 'Total Offers',
                dataIndex: 'total',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <Button>Create</Button>
            }
        ];

        const columns1 = [
            {
                title: 'Tool',
                dataIndex: 'tool',
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
                ellipsis: true,
                ...this.getColumnSearchProps('name')
            },
            {
                title: 'Start Date',
                dataIndex: 'sDate',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'sDate' && sortedInfo.order
            },
            {
                title: 'End Date',
                dataIndex: 'topic',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'eDate' && sortedInfo.order
            },
            {
                title: 'Totals',
                dataIndex: 'totals',
                ellipsis: true,
                sorter: (a, b) => a.name.length - b.name.length,
                sortOrder: sortedInfo.columnKey === 'totals' && sortedInfo.order
            },
            {
                title: 'Operation',
                dataIndex: 'action',
                ellipsis: true,
                render: (v) => <Button>Create</Button>
            }
        ];

        return (
            <div>
                <div className="advertisement2">
                </div>
                <header className="header2 bg-light">
                    <nav className="navbar  navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>
                            <div style={{ display: 'flex' }}>
                                <Link className="home-btn" to="/"><i className="fas fa-home"></i></Link>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" id="collapsingNavbar">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item"><Link className="nav-link" to="/account">Profile</Link> </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Settings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                            <Link className="dropdown-item" to="/qanda">Question & Answer settings </Link>
                                            <Link className="dropdown-item" to="/focusgroup">Focusgroup settings</Link>
                                            <Link className="dropdown-item" to="/1to1">1 To 1 Offer settings</Link>
                                            <Link className="dropdown-item" to="/ad">Promotional Ad settings</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/subscription">Subscriptions</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/invoice">Invoices</Link> </li>
                                    <li className="nav-item"><Link className="nav-link" to="/login">Logout</Link> </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>

                <div className="main-content-bg">
                    <section>
                        <section className="main-content-bg2">
                            <div className="center1">
                                <div className="card2">
                                    <h2><b>OVERVIEW</b></h2>
                                    <br />
                                    <h4><b>Insights 1 To 1 Offers</b></h4>
                                    <br />
                                    <br />
                                    <Table
                                        className="components-table-demo-nested"
                                        onChange={this.handleChange}
                                        columns={columns}
                                        dataSource={data}
                                        bordered
                                        expandedRowRender={this.expandedRowRender}
                                        title={() => <div className="table-operations">
                                            <Button onClick={this.setAgeSort}>Sort Name</Button>
                                            <Button onClick={this.clearAll}>Clear sorters</Button>
                                        </div>}
                                        style={{ width: '100%' }}
                                        tableLayout="fixed"
                                        scroll={{ x: 1000, y: 300 }}
                                    />
                                </div>
                            </div>

                            <div className="center1">
                                <div className="card2">
                                    <h2><b>OVERVIEW</b></h2>
                                    <br />
                                    <h4><b>Insights Marketing</b></h4>
                                    <br />
                                    <br />
                                    <Table
                                        className="components-table-demo-nested"
                                        onChange={this.handleChange}
                                        columns={columns1}
                                        dataSource={data}
                                        bordered
                                        expandedRowRender={this.expandedRowRender1}
                                        title={() => <div className="table-operations">
                                            <Button onClick={this.setAgeSort}>Sort Name</Button>
                                            <Button onClick={this.clearAll}>Clear sorters</Button>
                                        </div>}
                                        style={{ width: '100%' }}
                                        tableLayout="fixed"
                                        scroll={{ x: 1000, y: 300 }}
                                    />
                                </div>
                            </div>
                        </section>
                        <Footer {...this.props} />
                    </section>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Insight)