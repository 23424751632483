import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Typography } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'

const { Title } = Typography

class ShowModal extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { visible, currentQuestion, hideModal } = this.props
        return (
            <div>
                <Modal
                    visible={visible}
                    title="Question"
                    footer={[
                        <Button onClick={hideModal} key="submit" type="primary">
                            Ok
                    </Button>
                    ]}
                >
                    <Title level={3}>Question:- {currentQuestion.question}</Title>
                    {currentQuestion.answers.map((v, i) => {
                        return <Title level={3} key={i}>Answer{i+1}:- {currentQuestion.allAnswers[v]}</Title>
                    })}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ShowModal)