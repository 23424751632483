import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { loginUser } from '../Redux/actions/authActions'
import { Button } from 'antd'



class SubmitButton extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { loading, disable } = this.props
        return (
            <div className="submit-btn">
                <Button
                    htmlType="submit"
                    className="btn"
                    loading={loading}
                    disabled={disable}
                    style={{ backgroundColor: '#0587EF', color: 'white', fontWeight: 'bold', fontSize: 14, height: 50, display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                    Save
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton)