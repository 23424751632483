import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Typography, Checkbox, Row, Col } from 'antd';
import { loginUser } from '../../Redux/actions/authActions'

const { Title } = Typography

class QuestionModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: {
                question: this.props.question.question,
                answers: [],
                allAnswers: this.props.answers
            }
        }
    }

    updateSelected(e) {
        const { selected } = this.state
        this.setState({ selected: { ...selected, answers: e } })
    }

    handleOk = () => {
        const { selected } = this.state
        const { openNotification, handleOk } = this.props
        if (selected.answers.length) {
            handleOk(selected)
        }
        else {
            openNotification('Error', 'Please Select atleast one', 'close-circle', 'red')
        }
    }

    render() {
        const { visible, handleCancel, question, answers } = this.props
        return (
            <div>
                <Modal
                    visible={visible}
                    title="Question"
                    onOk={this.handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            Return
                    </Button>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            Submit
                    </Button>,
                    ]}
                >
                    <Title level={3}>{question.question}</Title>
                    <Checkbox.Group style={{ width: '100%' }} onChange={(e) => this.updateSelected(e)}>
                        <Row>
                            {Object.entries(answers).map((v, i) => {
                                return <Col span={24} key={i}>
                                    <Checkbox value={v[0]}>{v[1]}</Checkbox>
                                </Col>
                            })}
                        </Row>
                    </Checkbox.Group>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: (user) => dispatch(loginUser(user)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(QuestionModal)